import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { CustomerQuote } from "../components/site";
import { Container, Wrapper, Box } from "../components/util";
import { SignupForm } from "../components/forms";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Logo from "../images/logos/Droppah/Droppah_Full_White.svg";
import Library from "../images/graphics/rebrand/Upskill_alt.svg";
import FromFlexiTime from "../images/logos/FlexiTime/FromFlexiTime_Horiz_White.svg";
const FullContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 100vh;
  background-color: ${(props) => props.theme.colours.deeppurple};
  .fixed-logo{
    position: fixed;
    top: 40px;
    right: 40px;
    width: 300px;
    z-index: 1000;
    @media (max-width: 1300px) {
      display: none;
    }
  }
`;

const SubContainer = styled(Box)`
  width: 40%;
  margin: 20px;
  height: 100%;
  max-height: 100%;

   @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      width: 100%;
    }
  &.sign-up-right{
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 500px;
      max-width: 30vw;
      height: 100%;
      max-height: calc(100vh - 250px);
    }
    @media (max-width: 1300px) {
      display: none;
    }
  }

  &.sign-up-left{
    width: 60%;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    min-width: 800px;
    @media (max-width: 1300px) {
      width: 100%;
      max-width: 600px;
    }
    .mobile-logo{
      display: none;
      @media (max-width: 1300px) {
        display: block;
        max-width: 400px;
        margin-bottom: 40px;
      }
    }
    
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      padding: 0px;
      min-width: 0px;
       .mobile-logo{
          width: 100%;
          margin-bottom: 40px;
       }

      div{
        width: 100%;
      }
    }
            
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      h1{
        display: none;
      }
      h4{
        display: none;
      }
    }
      @media (min-height: 850px) {
           h1{
        display: block;
      }
      h4{
        display: block;
      }
    }
    h1{
      font-size: 56px;
      @media (max-width: 1300px) {
        font-size: 48px;
      }
     
      @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-size: 28px;  
      }
      @media (max-height: 870px) {
         font-size: 32px;  
      }
    }
    h4{
      margin: 10px 0px 30px 0px;
        @media (max-height: 870px) {
         font-size: 20px;  
      }
    }
    @media (max-height: 800px) {
      h4{
        display: none
      }
      h1{
        display: none
      }
    }
    

    a{
      color: ${(props) => props.theme.colours.white};
      text-decoration: none;
      span{
        margin-left: 5px;
        text-decoration: underline;
      }
    }
  }
`



const Signup = (props) => (
  <Layout fullPage>
    <Seo
      title="Create Account | Free for up to 10 Employees | Droppah"
      description="Discover Droppah, staff scheduling, time tracking & attendance software for your business. Let's do it."
      pathname={props.location.pathname}
    />
    <FullContainer>
      <img src={Logo} alt="Droppah" className="fixed-logo" />

      <SubContainer className="sign-up-left">
        <div>
          <img src={Logo} alt="Droppah" className="mobile-logo" />

          <h1>
            Suss your schedule in a sec with Droppah.
          </h1>
          <h4>
            Let’s get stuck in.
          </h4>
          <SignupForm center submitText="Create Account" candidateSignup={false} />

          <Link to="/login">
            Already have an account?
            <span>
              Sign in here
            </span>
          </Link>


          <a css={{ marginTop: "40px" }}
            className="link-floating"
            href="https://www.flexitime.works"
            target="_blank"
          >
            <img
              src={FromFlexiTime}
              alt="FlexiTime | Workforce Management for Modern Employers"
              height="18"
            />
          </a>

        </div>


      </SubContainer>

      <SubContainer className="sign-up-right">
        <img src={Library} alt="Upskill Library" />

      </SubContainer>

    </FullContainer>
  </Layout>
);

export default Signup;
